
import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Crash.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';
import CrashPhoto1 from "../../../../res/Photos site/Crash/Crash - 1983 - Van Boymans .png"
import CrashPhoto2 from "../../../../res/Photos site/Crash/Crash studio by crash.jpg"
import CrashPhoto3 from "../../../../res/Photos site/Crash/crash-1981.jpg"
import CrashPhoto4 from "../../../../res/Photos site/Crash/photo _ crash 1989 solo show speestra monaco.jpg"
import Button from "../../../../components/Button";
import Pdp from '../../../../res/crash/portrait.png'
import PastPresent1 from "../../../../res/crash/past-present-1.jpg"
import PastPresent2 from "../../../../res/crash/past-present-2.jpg"
import PastPresent3 from "../../../../res/crash/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "Crash",
  name: "Crash",
  description: 
  "Born in 1961, John Matos, better known as CRASH, hails from the Bronx. As a pioneer and figurehead of the movement, his bold artistic path has been essential in redefining the boundaries of New York graffiti and post-graffiti... We are in 1975. John imposes CRASH on the streets of New York, invading the subway cars and the city with his colorful creations. These mobile surfaces have become ephemeral canvases that he invests like a rebellious teenager, who thinks and affirms that graffiti is not vandalism.  Alongside his friends, he explored the Wild Style, an artistic distortion of lettering that became the bold signature of New York graffiti in the 1970s-1980s. Then, in 1979, he joined Studio Graffiti, which enabled artists - such as Dondi, Futura and Daze - to turn to studio production. This moment marked the beginning of a new chapter in his career, as he passionately embraced the Post-graffiti movement, a movement that would in some ways redefine the perception of contemporary art.",
  pdp: Pdp,
  alt_pdp: "Photo de profil de Crash.",
  photos: [
    { src: PastPresent1, name: "Crash" },
    { src: PastPresent2, name: "Crash" },
    { src: PastPresent3, name: "Crash" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

// const exhibitions = [
//   {
//     date: "Avril 2018",
//     title: "Crash",
//     link: "dondi-white",
//     artist: "Crash",
//     place: "2 rue de Belloi - 13006 Marseille",
//     description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sem orci, scelerisque sit amet placerat lacinia, tincidunt at metus. Integer euismod ligula et nisl bibendum, sit amet consectetur nulla vulputate. Vivamus luctus velit tellus, finibus lobortis ipsum tempor at. Quisque facilisis dui eget eros interdum aliquet. Maecenas eu placerat purus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam quis sem non ligula pretium sagittis.",
//     presentation_photo: CrashPhoto,
//     photos_paths: ["path", "path", "path", "path"],
//   },
// ]


const Crash = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
      <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>CRASH <h2 style={{paddingLeft: "16px"}}>(John Crash Matos - American)</h2></h1>
          <h3 className={NameWrapper} >Born in 1961</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />

        <ul className={ArtistInfos}>
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2024</p>
          <p>- Echoes of the Past : Timeless creativy & Exploration from 1980 to 1990, Ghost Galerie, Paris, France</p>
          <p style={{fontWeight: "bold"}}>2023</p>
          <p>- CRASHONE : Subway/Canvas/Street,Oever Gallery Ostend, Belgium.</p>
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- John “CRASH” Matos: Shape Of Things To Come ,JoAnne Artman Gallery, New York ,Chelsea, New York, USA.</p>
          <p>- Crash One: Scripted Memories ,Thinkspace Projects ,Culver City, Los Angeles, California, USA.</p>
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- CRASH x Warner Bros: Space Jam: A New Legacy ,Art in the Parks ,New York, USA.</p>
          <p>- Gallery Art Summer 2021 Exhibition, Gallery Art, Aventura, USA, July 1 st - 31st.</p>
          <p>- Icons & Vandals, West Chelsea Contemporary, Austin, USA, May 15th - July 11th.</p>
          <p>- THE PRINT ROOM, Aurum Gallery, Bangkok, Thailand, April 1st - June 30th.</p>
          <p>- THAT 70s SHOW : SATURDAY NIGHT FEVER, John ‘CRASH’ Matos, Michael Callas, Anja Van Herle + Jana Cruder, JoAnne Artman Gallery, New York, USA, April 13 th - June 1st.</p>
          <p>- Modern Masters, End to End Gallery, Hollywood,CA, March 20th - May 30th.</p>
          <p>- JoAnne Artman Gallery at Affordable Art Fair New York, Spring 2021, BOOTH B39, New York, USA, May 19th - 23th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- John ‘CRASH’ Matos : CELEBRATING 45 YEARS, Joanne Artman Gallery, New York, USA, September 1st - October 31st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Precious, AtDown Galerie, Montpellier, France, October 26th - November 30th.</p>
          <p>- John CRASH Matos feature, Art Elysées Art and Design Art Fair, Paris, France, October 17th - 21st.</p>
          <p>- CRASH: Textures, Kolly Gallery, Zurich, Switzerland, October 7th - October 12th.</p>
          <p>- Concrete Jungle: Featuring John ‘Crash’ Matos, JoAnne Artman Gallery, Chelsea, New York, USA, November 8th, 2018 - January 19th, 2019.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Full Circle: John Matos Crash, Brugier-Rigail Galerie, Paris, France, September 7th - 23rd.</p>
          <p>- Life of Icons, Speerstra Gallery, Paris, France, January 27th - February 24th.</p>
          <p>- Coming Full Circle, Southport Galleries, Southport, UK.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- The Color Swatches, Kolly Gallery, Zurich, Switzerland, June 15th - July 15th.</p>
          <p>- All About Print 2, Galerie Slika, Lyon, France, December 15th, 2016 - January 15th, 2017.</p>
          <p>- Scrabble, AtDown Galerie, Montpellier, France.</p>
          <p>- Breaking Ground : Defining The Urban Experience, JoAnne Artman Gallery, Laguna Beach, USA.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Breaking Ground; Defining The Urban Experience, JoAnne Artman Gallery, Chelsea, New York, USA, September 8th - October 31st.</p>
          <p>- Heroes are Forever, Brugier-Rigail Galerie, Paris, France, September 2nd - 27th.</p>
          <p>- Movement Revisited, with PRO176, Galerie Zimmerling & Jungfleisch, Saarbrucken, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- John Matos, CRASH, Galerie Helenbeck, Nice, France, March 11th - May 16th.</p>
          <p>- Crash-Recent Small Works, bOb’s, New York, USA.</p>
          <p>- John CRASH Matos, at Galerie Artime, Paris, France.</p>
          <p>- John CRASH Matos, Then and Now, Villa Tamaris Centre d’Art, La Seyne-Sur-Mer, Toulon, France.</p>
          <p>- John CRASH Matos: “1990-25 Years Later”, At-Down Galerie, Montpellier, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- FLOW, John “CRASH” Matos and Remi Rough, Collaborative paintings, Dorian Grey Gallery, New York, USA.</p>
          <p>- CRASH, ArtPalmBeach, Palm Beach, USA.</p>
          <p>- Broken English, Jonathan LeVine Gallery, New York, USA.</p>
          <p>- Crash, Broken Language, Lawrence Fine Arts, East Hampton, USA.</p>
          <p>- Crash, Recent Paintings:, Paul Fisher Gallery, West Palm Beach, USA.</p>
          <p>- Crash ! An Exhibition of Paintings and Drawings, Steven Vail Fine Arts, Iowa, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- PoP-Eye, Galerie Brugier-Rigail, Paris, France.</p>
          <p>- Crash, Hide N Seek, Southport Galleries, Southport, USA.</p>
          <p>- Pop Art and Graffiti, Galerie Raison d’art, Lille, France.</p>
          <p>- CRASH, Taipei Art Fair, M.A.D., Taipei, Taipei, Taiwan.</p>
          <p>- John Crash, Matos, Art Elysées, Velvet Galerie, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Remnant Memories, Toy Tokyo Underground, New York, USA.</p>
          <p>- CRASH, Study in Watercolors, Addict Galerie, Paris, France.</p>
          <p>- Crash, Misdemeanors to Masterpieces, Long-Sharp/Curis Modern and Contemporary Fine Art, Detroit, USA.</p>
          <p>- CRASH, The True Essence..., Speerstra Gallery, Bursins, Switzerland.</p>
          <p>- The Real Crash, Galerie Brugier-Rigail, Paris, France.</p>
          <p>- Crash, Letting Ink Dry, Wallworks Gallery, Paris, France.</p>
          <p>- Crash, Back on the Block, Antiquorum Galleries, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- The Street Art Show, Opera Gallery, London, UK.</p>
          <p>- Paintings With a Hidden Agenda, Addict Galerie, Pairs, France.</p>
          <p>- Mural Installation, with TATS CRU and DAZE, Bronx, New York, USA.</p>
          <p>- Black to Basics, bOb, New York, USA.</p>
          <p>- 25 years of Prints, Southport Galleries, Southport, USA.</p>
          <p>- Blurring the Lines, Crash, Risk and Freedom, Corey Helford Gallery, Los Angeles, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- From the 4 Trains, to Fenders, A Retrospective, Thomas J. Walsh Art Gallery, Fairfield, CT, January 28th - February 29th.</p>
          <p>- Crash, The Tin Machines, Addict Galerie, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Crash, Betances 1973 - 82m Addict Galerie, Paris, France</p>
          <p>- Crash, Los Angeles Art Show, FADA, Los Angeles, USA.</p>
          <p>- CRASH by CRASH, La Galerie Halenbeck, Nice, France.</p>
          <p>- Unfinished Business, Joshua Liner Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Crash, The Art of the Line, Speerstra Gallery, Bursins, Switzerland.</p>
          <p>- Crash, Lafayette College’s Williams Art Gallery, Easton, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Kaleidoscope : Paintings, Prints and Works on Paper, Wooster Projects, New York, USA.</p>
          <p>- Crash, Galerie Onega, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- The Lucky Strike Diaries, Speerstra Gallery, Bursins, Switzerland.</p>
          <p>- Crash, Lehmann/Leskiw Fine Arts, Toronto, Canada.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Second Skin, New Paintings by CRASH, Wooster Projects, New York, USA.</p>
          <p>- Second Skin, Paintings by CRASH, Sponsored by Stussy, Japan.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Pop, Boom, Crash, Animation and Fine Arts Gallery, New York, USA.</p>
          <p>- Crash, Recent Paintings, Prosper, Tokyo, Japan.</p>
          <p>- CRASH, Fender Stratocaster 50th Anniversary, Fender Museum, Corona, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- Barrions, ArtLink International, Lake Worth, FL; Angeles, USA.</p>
          <p>- Crash : The Train Paintings, Galerie Speerstra, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- The Power of Crash, Art Link International Gallery, Lake Worth, USA.</p>
          <p>- CRASH, Notes from the Westside, Galerie Speerstra, Paris, France.</p>
          <p>- The Train Paintings, Galerie Speerstra, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Breaking Curfew, CPOP Gallery, Detroit, USA.</p>
          <p>- CRASH, Fine Arts and Artists, Washington, D.C, USA.</p>
          <p>- Installation, Prosper, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- VIPS International, Rotterdam, The Netherlands.</p>
          <p>- Graffiti Indoors, AZ/NY Contemporary, Scottsdale, USA.</p>
          <p>- Dorothy Blau Gallery, Bay Harbor Islands, USA.</p>
          <p>- CRASH, The Timetable, Galerie BLU, Pontiac, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- American Graffiti: CRASH & DAZE, Musée d’Art Moderne et d’Art Contemporain, Nice, France.</p>
          <p>- CRASH and DAZE: On the Express Train 1977-1999, Palais Liechtenstein, Reldkirch, Australia.</p>
          <p>- CRASH and DAZE: On the Express Train 1977 - 1999, GroBmarkhalien, Munich, Germany.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- CRASH / DAZE, Enrico Coeri, Florence, Italy.</p>
          <p>- CRASH, Verticals and Horizontals, Mary Anthony Galleries, New York, USA.</p>
          <p>- CRASH, Vali/Giesler Contemporary Art, Des Moines, USA.</p>
          <p>- CRASH, The Exhibition, Masterpiece Gallery, Birmingham, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- CRASH, 2nd Floor Limited, New Orleans, USA.</p>
          <p>- Bruce R. Lewin Gallery, New York, USA.</p>
          <p>- New York - New York : John CRASH Matos, Galeria Las Palomas, Gaucin, Spain.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Dorothy Blau Gallery, Bay Harbor Islands, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- CRASH The Villains, Mary Anthony Galleries, New York, USA.</p>
          <p>- Gallery Cotthem, Knokke-Zoute, Belgium.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- CRASH, Works on Paper, The Hobbs Gallery, Boston, USA.</p>
          <p>- CRASH, Mary Anthony Fine Arts, New Hope, USA.</p>
          <p>- CRASH/DAZE Recent Works, York College, a City University of New York, Jamaica, Queens, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1993</p>
          <p>- Hostos Arts Gallery of Eugenio Mais de Hostos Community College of the City of New York, Bronx, USA; Gallery Cotthem, Zotegem, Belgium.</p>
          <p>- CRASH & DAZE, Deux Peintres New Yorkais, Galerie de la Cathédrale, Monaco, France. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- New Paintings by CRASH, Sidney Janis Gallery, New York, USA.</p>
          <p>- John CRASH Matos: New Works, C’an Isidro, Ibiza, Spain.</p>
          <p>- CRASH Superheroes, Martin Lawrence Modern, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Galerie Michel Gillet, Paris, France.</p>
          <p>- CRASH, Galerie Artcade, Nice, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- CRASH & DAZE, Gallery Structure, Montpellier, France.</p>
          <p>- Hokin Gallery, Bay Harbor Islands, USA.</p>
          <p>- CRASH & DAZE, Black and White in Color Gallery, Bronx, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}> 1989</p>
          <p>- Tamara Bane Gallery, Los Angeles, USA.</p>
          <p>- Hokin Gallery, Bay Harbor Islands, USA.</p>
          <p>- CRASH, B5 Gallery, Monaco, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1988</p>
          <p>- Sidney Janis Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- CRASH!, The Manhattan Borough President David N. Dinkins Municipal Building, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- Jus de Pomme Gallery, New York, USA.</p>
          <p>- Sidney Janis Gallery, New York, USA.</p>
          <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Yaki Kornblit Gallery, Amsterdam, The Netherlands.</p>
          <p>- 51X Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- Fashion Moda, Bronx, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Real Art Ways, Hartford, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p>- Winter Recap ,Oever Gallery ,Ostend, Belgium</p>
          <p>- Wild Style 40 ,Jeffrey Deitch, New York (Wooster Str) ,Soho, New York, USA.</p>
          <p>- Style Writers ,Mucciaccia Gallery, London ,Mayfair, London, UK.</p>
          <p>- Beyond the Streets London ,Saatchi Gallery ,London, UK.</p>
          <p>- Gallery Collection 23’ ,Mucciaccia Gallery, Rome ,Rome, Italy.</p>
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Kablam! Pop Art/Op Art from the Collection ,MOAS, Museum of Arts and Sciences ,Daytona Beach, Florida, USA.</p>
          <p>- Accrochage saisonnier, Ghost Galerie, Paris, France, Janvier-Juin 2022</p>
          <p>- Crash, galerie At Down, Montpellier, District 13 International Art Fair Drouot, 15-18 Septembre 2022.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Beyond The streets on paper, SouthAmpton Arts Center, SouthAmpton, New-York, USA, July 17th - August 28th .</p>
          <p>- Pow! WOW! The First Decade from Hawaï to the world, Thinkspace projects, Culver city, Los Angeles, California, USA, May 15th - September 19th.</p>
          <p>- BEYOND THE STREETS on PAPER, Southampton Arts Center, Southampton, USA, July 17th - August 28th.</p>
          <p>- Street Art & Urban Art, Alpha 137 Gallery, New York, USA, August 2nd - October 19th.</p>
          <p></p>
          <p> - Nos Fantômes - exposition inaugurale, Ghost galerie, Paris, France, 10 Septembre - 20 Novembre, 2021.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Collecting by Color : Pink, Alpha 137 Gallery , New York, USA.</p>
          <p>- WYNWOOD WALLS : 10 YEAR RETROSPECTIVE GROUP SHOW, GGA Gallery, Miami, USA.</p>
          <p>- BEYOND THE STREETS presented by NTWRK, BEYOND THE STREETS, Brooklyn, USA.</p>
          <p>- Rare Finds, ArtWise, Brooklyns, USA.</p>
          <p>- Concrete To Canvas, West Chelsea Contemporary, Austin, USA.</p>
          <p>- Art With Text : The Message is the Medium, Alpha 137 Gallery, New York, USA.</p>
          <p>- P O P.u.lar : Featuring GREG MILLER, JOHN ‘CRASH’ MATOS + MICHAEL CALLS, JoAnne Artman Gallery, New York, USA.</p>
          <p>- FACES OF STREET ART, Aurum Gallery, Bangkok, Thailande.</p>
          <p>- Man Cave Decor, ArtWish, Brooklyns, USA.</p>
          <p>- VOCABULARY OF EXPRESSION, Kolly Gallery, Zürich, Suisse.</p>
          <p>- Aurum Gallery Opening Show, Aurum Gallery, Bangkok, Thailand.</p>
          <p>- ONLINE : One World, Fabien Castanier Gallery, Little River, April 18th - May 31th.</p>
          <p>- ONLINE : Sprayed, Show me yours cans, Joanne Artman Gallery, New-York, May 7th - June 20th</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Pow! WOW! Hawai’i : Exploring the New Contemporary Movement VI, Thinkspace projects, Honolulu, Havaï, February 9th - February 19th .</p>
          <p>- LAX: SFO Part III, Heron Arts, San Francisco, California, USA, June 15th - July 6th .</p>
          <p>- LAX-MSY, Thikspace projects, Culvey city, california, USA, August 10th , August 30th .</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- CRASH, PRO176, SOTEN, 44309 street art gallery, Dortmund, Germany.</p>
          <p>- Bloom / Wilt / Bloom, Elga Wimmer PCC, Chelsea, New York, USA, April 12th - May 05th.</p>
          <p>- Art from the streets, Art Science museum, Singapore, January 13th - June 3th .</p>
          <p>- Unsanctionned: Voices of the wall, Opera Gallery, Singapore, JUne 7th - June 22th .</p>
          <p>- Iconic, Woodward Gallery, Soho, New York, USA, September 15th - October 26th .</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- Glamorous Graffiti: Basquiat, Crash, Haring & More, NAssau Country Museum of arts, Roslyn, New York, USA, March 19th - June 10th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- Pop Rocks : Heroes, Icons and the Mundane, The A.E Backus Museum & Gallery, March 12th - May 2nd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Graffiti - New York 80 ́s, Galerie Jérôme de Noirmont, Paris, may 27th - july 20th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- New Works by John CRASH Matos and Aaron Sharp Goodstone, Galerie Onega, Paris, France.</p>
          <p>- Locked and Loaded, Joshua Liner Gallery, New York, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- The Silkscreen Pieces, Lucky Strike, Galerie Speerstra, Paris, France.</p>
          <p>- Collision II, (with Jahan), Jendela (Visual Arts Space), Singapore.</p>
          <p>- Collision I, (with Jahan), Esplanade Concourse, Singapore.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Dreamland Artist Club 2005, Coney Island Boardwalk, Brooklyn, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Collaboration, (Crash/ Herve DiRosa), Galerie Speersta, Paris, France. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2003</p>
          <p>- 100 Small Painting, RECON @, San Francisco, USA. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Barrios, Hostos Art Gallery, Hostos Community College/CUNY, Bronx, USA.</p>
          <p>- KAWS & CRASH, MADE gallery, Vancouver, Canada.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- INT. /EXT., Eastwick Gallery, Chicago, USA.</p>
          <p>- Collaboration : CRASH ONE, TRIAD, Paris, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2000</p>
          <p>- Projets United, Kunst Zurich 2000, Zurich, Switzerland.</p>
          <p>- From Pablo Picasso to Andy Warhol, Antico Monastero Santa Chiara, San Marino, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1999</p>
          <p>- BEST Buddies, Galerie Blue, Pontiac, USA.</p>
          <p>- Cadaver Dog, Art from the East Village/LES-the 1980’s, LIFE Café, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- Coming From The Subway : New York Graffiti Art, Museum Der Stadt Ratingen, Ratingen, Germany.</p>
          <p>- American Graffiti, Museo Andriade Muricy, Curitiba, Brazil; San Paolo, Brazil. </p>
          <br />
          <p style={{fontWeight: "bold"}}>1997</p>
          <p>- Spraycan Art from the Ludwig Collection and other Collections, Machio Angioino, Naples, Italy; Chiostro del Bramante, Rome, Italy; Florence, Italy.</p>
          <p>- Unholy Lust, Todd Kaplan Gallery, Los Angeles, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1996</p>
          <p>- Everything That’s Interesting is New, The Dakis Joannou Collection at The Athens School of Fine Arts, Athens, Greece New York Unplugged II, Gallery Cotthem, Knokke-Zoute, Belgium.</p>
          <p>- The Usual Suspects, Avanti Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1995</p>
          <p>- Synaesthesia, Mary Anthony Galleries, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Influential Works in the 90’s, The Hobbs Gallery, Boston, USA.</p>
          <p></p>
          <p>- Urban Expressionism (The Hip Hop Movement), The Hudson Guild Art Gallery, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>PRIVATE COLLECTIONS</p>
          <br />
          <p>- Museum of Modern Art, New York, USA.</p>
          <p>- Stedelijk Museum, Amsterdam, The Netherlands.</p>
          <p>- Brooklyn Museum of Art, Brooklyn, USA.</p>
          <p>- Appleton Museum of Art, Ocala, USA.</p>
          <p>- Fort Wayne Museum, Fort Wayne, USA.</p>
          <p>- Mobile Museum of Art, Mobile, USA.</p>
          <p>- Museum of the City of New York, New York, USA.</p>
          <p>- Naples Museum of Art, Naples, USA.</p>
          <p id="end">- Orlando Museum of Art, Orlando, USA.</p>
          </div>
        </ul>
        <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      
      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/en/contactez-nous";
                }
              }
            }
            >
              Contact-us
            </Button>
        </div>
    </div>
  );
};

export default Crash;