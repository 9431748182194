// extracted by mini-css-extract-plugin
export var ArtistDescription = "Crash-module--ArtistDescription--+HnWh";
export var ArtistInfos = "Crash-module--ArtistInfos--DETgg";
export var ButtonWrapper = "Crash-module--ButtonWrapper --vZaPI";
export var CardWrapper = "Crash-module--CardWrapper--DhXFJ";
export var CarrouselWrapper2 = "Crash-module--CarrouselWrapper2--aW17Q";
export var Citations = "Crash-module--Citations--7d4UQ";
export var DescriptionWrapper = "Crash-module--DescriptionWrapper--Km-K1";
export var ImageWrapper = "Crash-module--ImageWrapper--ZYPVp";
export var LinkWrapper = "Crash-module--LinkWrapper--zjctB";
export var MobileProtrait = "Crash-module--MobileProtrait--NBCY4";
export var More = "Crash-module--More--Sh8YJ";
export var MoreButton = "Crash-module--MoreButton--n0l0a";
export var NameWrapper = "Crash-module--NameWrapper--zyXO+";
export var PdpWrapper = "Crash-module--PdpWrapper--Oswzr";
export var PhotosWrapper = "Crash-module--PhotosWrapper--RqVRH";
export var ProfilWrapper = "Crash-module--ProfilWrapper--M6ou9";
export var TitleWrapper = "Crash-module--TitleWrapper--6X7P8";
export var Wrapper = "Crash-module--Wrapper--Y+AGe";